import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Mollie's Motel Customer Story - Rotaready"
          description="Find out how implementing Rotaready has enabled Mollie's Motel to transform workforce management across the business"
          url="customer-stories/hospitality-mollies-motel"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Mollie's Motel entrance with red neon branding"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Mollie's Motel logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Find out how implementing Rotaready has enabled Mollie's Motel to transform workforce management across the business" />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="2" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="211" />
              <StatSubtitle text="employees" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Mollie's Motel" />
              </Content>

              <Content>
                <TextBlock text="Mollie's (mollies.com) is an affordable hotel and diner concept designed by the creative team at Soho House. Mollie's launched in 2019 with a purpose-built roadside Motel, Diner & Drive-Thru in Oxfordshire; followed in 2021 by a second Motel and Diner, just off the M5 at Cribbs Causeway, Bristol. With ambitious plans to roll-out a collection of cool design- and tech-led venues across the UK and it's latest city motel flagship planned for later this year, Mollie's is going places." />
              </Content>

              <Content>
                <TextBlock text="This case study explores Mollie's journey to integrate Rotaready into it's award winning tech ecosystem in as part of it's strategy to operate an effective workforce management solution." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Requirement" />
              </Content>

              <Content>
                <TextBlock text="With c200 employees and growing, Mollie's recognsied the need for a comprehensive workforce management solution that's cost-effective, user-friendly and operationally effective." />
              </Content>

              <Content>
                <H3 uistyle="brand160" text="Selecting Rotaready" />
              </Content>

              <Content>
                <TextBlock text="After an extensive evaluation process, Rotaready emerged as the preferred solution due to its unique combination of functionality, user-friendliness, and customer support. The system addressed Mollie's specific needs, including labour forecasting, reporting capabilities, employee journey tracking, holiday accrual calculations, document storage, and an open API for integrations into other areas if it's advanced tech ecosystem." />
              </Content>

              <Content>
                <H3 uistyle="brand160" text="Implementation and Integration" />
              </Content>

              <Content>
                <TextBlock text="Rotaready was seamlessly integrated into Mollie's over a four-week period. The system's flexibility allowed for a staggered approach, starting with staff scheduling and mobile clock-in/out, followed by reporting and payroll. Integrations with POS and reservation systems facilitated consistency in scheduling, resulting in a significant labour efficiencies and improved forecasting capabilities." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="A waitress holding two milkshakes on a tray"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Connected HR & People Suite" />
              </Content>

              <Content>
                <TextBlock text="Mollie's achieved a connected HR & People suite by integrating Rotaready with their applicant tracking and training solutions. This consolidation streamlined the employee experience, from initial interaction to ongoing training and engagement, improving reporting capabilities on the entire employee journey." />
              </Content>

              <Content>
                <H3 uistyle="brand160" text="Holiday Management Efficiency" />
              </Content>

              <Content>
                <TextBlock text="Rotaready addressed their need for effective holiday management by automating accrual calculations. The system allowed for different rules based on salary or hours accrued, providing accuracy and efficiency in a process that was previously manual. The overall holiday management process also improved, preventing key dates with excessive staff absences." />
              </Content>

              <Content>
                <H3 uistyle="brand160" text="Customer Success and Future Plans" />
              </Content>

              <Content>
                <TextBlock text="Rotaready's customer success team continues to collaborate closely with Mollie's to maximize the system's features and enhance return on investment. The phased implementation approach, covering staff scheduling, mobile clock-in/out, reporting, payroll, and document storage, will soon extend to self-onboarding. Integrating Rotaready into training and applicant tracking solutions remains a priority to ensure a seamless and efficient employee journey." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Plates of breakfast waffles covered in ice cream, fried eggs and streaky bacon"
              />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Since integrating Rotaready into our business, we have transformed our workforce management, seamlessly catering for our scheduling needs while delivering helpful functionality to the operational teams."
                />
              </Content>
              <Content>
                <Blockquote
                  text="What sets Rotaready apart is not just its technology but the support we receive and the collaborative attitude to work with us as we grow, actively seeking feedback and innovating based on our needs. It's evident that their main mission is to enhance our operational experience, making it more efficient and hassle-free."
                />
              </Content>
              <Content>
                <Blockquote
                  text="The integration of Rotaready with our other operational systems has brought consistency to our scheduling and alignment with our forecasts. Consolidating our HR and People functionality was a priority for us, and Rotaready played a crucial role in achieving this."
                />
              </Content>
              <Content>
                <Blockquote
                  text="Looking ahead, we are excited about further exploring Rotaready's features, with the ongoing support of their customer success team."
                  attribution="Mike Hogan, Head of Operations, Mollie's"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_molliesmotel"
                label="rotaready.com/customer-stories/hospitality-mollies-motel"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Mollies-Motel.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/mollies-motel/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/mollies-motel/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 60, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/mollies-motel/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/mollies-motel/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
